export const ServicesStrings = {
    title: "Our Service Packages",
    description: "Contact us for a complimentary consultation and custom package quote.",
    description2: "Each package includes the previous package's services.",
    consultationAndDesign: {
        name: "Consultation + Design",
        description: `Our consultations are always complimentary and essential regardless of packages. We love getting to know you! \n Add on the option of our team designing the event you've been dreaming of.`
    },
    decor: {
        name: "Custom Décor",
        description: "Love our design but not sure you can implement it by yourself? Let us take the pressure off! We can create all the décor for your themed party.",
        bonus: "consultation & design"
    },
    catering: {
        name: "Catering",
        description: "Don't want to resort to take-away pizzas again? We've got you covered. Your guests will be eating and drinking in style with our themed catering package.",
        bonus: "custom décor"
    },
    dayOfCoordination: {
        name: "Day-Of Coordination",
        description: "Even when you have custom décor and food in hand, putting it all together in a beautiful arrangement can be time consuming and overwhelming. Let us do set-up and event coordination for your big day.",
        bonus: "catering"
    },
    consultation: {
        title: "Ready to party?",
        description: "Contact us for a complimentary consultation and custom package quote.",
        buttonText: "Book Now"
    }
}