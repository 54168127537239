import * as React from "react"
import { Padding } from "./Styles"
import styled from "styled-components"



const StyledBonuses = styled.h3`
    margin-bottom: -20px;
`

const Mobileh2 = styled.h2`
font-size: 24px;
`

const Mobileh3 = styled.h2`
font-size: 18px;
`

const Mobilep = styled.p`
font-size: 18px;
`

const ConsultationInfo = ({ name, info, image, bonus, bonus1, bonus2, mobile }) => {
    if (mobile) {
        return (<div style={{ padding: Padding.gutter }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {image}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <Mobileh2>{name}</Mobileh2>
            </div>
            <mobileh3>{info}</mobileh3>
            <StyledBonuses>{bonus && <Mobilep>+ {bonus}</Mobilep>}</StyledBonuses>
            {bonus1 && <StyledBonuses>{bonus1 && <Mobilep>+ {bonus1}</Mobilep>}</StyledBonuses>}
            {bonus2 && <StyledBonuses>{bonus2 && <Mobilep>+ {bonus2}</Mobilep>}</StyledBonuses>}
        </div>)
    }
    else {
        return (
            <div style={{ padding: Padding.gutter }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {image}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <h2>{name}</h2>
                </div>
                <h2>{info}</h2>
                <StyledBonuses>{bonus && <p>+ {bonus}</p>}</StyledBonuses>
                {bonus1 && <StyledBonuses>{bonus1 && <p>+ {bonus1}</p>}</StyledBonuses>}
                {bonus2 && <StyledBonuses>{bonus2 && <p>+ {bonus2}</p>}</StyledBonuses>}
            </div>
        )
    }
}

export default ConsultationInfo