import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Seo from "../components/seo"
import ConsultationInfo from "../components/consultationInfo"
import { ServicesStrings as Services } from "../content/ServicesText"
import { PPEButton } from "../components/PPEButton"
import { Colors, Padding } from "../components/Styles"
import { Divider } from "@mui/material"

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useEffect, useState } from 'react'

const Text = styled.h1`
margin: 10px;
justify-content: center;
display: flex;
font-weight: normal;
`

const Texth2 = styled.h2`
margin: 10px;
justify-content: center;
display: flex;
font-weight: normal;
`

const MobileH1 = styled.h1`
font-size: 50px;
padding-top: 10px;
`

const MobileH2 = styled.h2`
font-size: 18px;
`

const icons = {
  consultation: <QuestionAnswerIcon fontSize="large" />,
  decor: <BorderColorIcon fontSize="large" />,
  catering: <EmojiFoodBeverageIcon fontSize="large" />,
  coordination: <CampaignIcon fontSize="large" />
}

const ServicesPage = () => {
  const [isDesktop, setDesktop] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 920)
    }
  }, [])

  const updateMedia = () => {
    setDesktop(window.innerWidth > 920)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })
  if (isDesktop == true) {
    return (
      <div>
        <div style={{ width: '100%', backgroundColor: Colors.purple, padding: Padding.gutter, color: Colors.white }}>
          <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
            <Text>{Services.title}</Text>
            <Texth2>{Services.description}</Texth2>
            <Texth2>{Services.description2}</Texth2>
          </AnimationOnScroll>
        </div>
        <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between', backgroundColor: Colors.pink, padding: Padding.gutter }}>
          <ConsultationInfo name={Services.consultationAndDesign.name} info={Services.consultationAndDesign.description} image={icons.consultation} />
          <Divider width={2} orientation="vertical" flexItem light />
          <ConsultationInfo name={Services.decor.name} info={Services.decor.description} bonus={Services.decor.bonus} image={icons.decor} />
          <Divider width={2} orientation="vertical" flexItem light />
          <ConsultationInfo name={Services.catering.name} info={Services.catering.description} bonus={Services.catering.bonus} bonus1={Services.decor.bonus} image={icons.catering} />
          <Divider width={2} orientation="vertical" flexItem light />
          <ConsultationInfo name={Services.dayOfCoordination.name} info={Services.dayOfCoordination.description} bonus={Services.dayOfCoordination.bonus} bonus1={Services.catering.bonus} bonus2={Services.decor.bonus} image={icons.coordination} />
        </div>
        <div style={{ display: "inline-flex", width: "100%", backgroundColor: Colors.cream }}>
          <div style={{ width: "50%", padding: Padding.gutter, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
              <h1>{Services.consultation.title}</h1>
              <h2>{Services.consultation.description}</h2>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
              <PPEButton wide buttonText={Services.consultation.buttonText} link={"/Contact"} centered />
            </AnimationOnScroll>
          </div>
          <StaticImage
            src="../images/services1.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            style={{ width: "50%" }} />
        </div>
      </div>
    )
  }
  else if (isDesktop == false) return (
    <div style={{ paddingTop: "95px" }}>
      <div style={{ width: '100%', backgroundColor: Colors.purple, padding: Padding.gutter, color: Colors.white }}>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
          <MobileH1 style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>{Services.title}</MobileH1>
          <MobileH2 style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>{Services.description}</MobileH2>
          <MobileH2 style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>{Services.description2}</MobileH2>
        </AnimationOnScroll>
      </div>
      <div style={{ backgroundColor: Colors.pink }}>
        <ConsultationInfo mobile name={Services.consultationAndDesign.name} info={Services.consultationAndDesign.description} image={icons.consultation} />
        <ConsultationInfo mobile name={Services.decor.name} info={Services.decor.description} bonus={Services.decor.bonus} image={icons.decor} />
        <ConsultationInfo mobile name={Services.catering.name} info={Services.catering.description} bonus={Services.catering.bonus} bonus1={Services.decor.bonus} image={icons.catering} />
        <ConsultationInfo mobile name={Services.dayOfCoordination.name} info={Services.dayOfCoordination.description} bonus={Services.dayOfCoordination.bonus} bonus1={Services.catering.bonus} bonus2={Services.decor.bonus} image={icons.coordination} />
      </div>
      <StaticImage
        src="../images/services1.jpg"
        loading="eager"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="" />
      <div style={{ width: "100%", padding: Padding.mobileGutter, justifyContent: 'center' }}>

        <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
          <MobileH1>{Services.consultation.title}</MobileH1>
          <MobileH2>{Services.consultation.description}</MobileH2>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
          <PPEButton mobile buttonText={Services.consultation.buttonText} link={"/Contact"} centered />
        </AnimationOnScroll>
      </div>
    </div >
  )
}

export const Head = () => <Seo title="Services" />

export default ServicesPage
